import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import getRelativeUrl from '../utils/getRelativeUrl';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  to = getRelativeUrl(to);
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
        {children}
      </GatsbyLink>
    );
  }

  const isAnchor = to.includes('#');
  if (isAnchor) {
    return (
      <a href={to} {...other}>
        {children}
      </a>
    );
  } else {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...other}>
        {children}
      </a>
    );
  }
};

export default Link;
