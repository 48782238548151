import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default React.memo(({ isLight = false, ...other }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        options {
          options {
            global {
              logoDark {
                ...Image
              }
              logoLight {
                ...Image
              }
            }
          }
        }
      }
    }
  `);

  const logoDark = wp.options.options.global.logoDark.localFile.publicURL;
  const logoLight = wp.options.options.global.logoLight.localFile.publicURL;
  const logo = isLight ? logoLight : logoDark;

  return <img src={logo} alt="Programmai Logo" {...other} />;
});
