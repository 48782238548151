const getRelativeUrl = (url) => {
  if (url && url.length) {
    // const WPURL = 'https://cms.programmai.madetogether.co'.split('/graphql')[0];
    const WPURL = 'https://programmai.com';
    return url.replace(WPURL, '');
  } else {
    return '';
  }
};

export default getRelativeUrl;
